$spaces: 100;
@mixin margin($size) {
  margin: $size + px;
}
@mixin margin-top($size) {
  margin-top: $size + px;
}
@mixin margin-left($size) {
  margin-left: $size + px;
}
@mixin margin-right($size) {
  margin-right: $size + px;
}
@mixin margin-bottom($size) {
  margin-bottom: $size + px;
}
@mixin padding($size) {
  padding: $size + px;
}
@mixin padding-top($size) {
  padding-top: $size + px;
}
@mixin padding-left($size) {
  padding-left: $size + px;
}
@mixin padding-right($size) {
  padding-right: $size + px;
}
@mixin padding-bottom($size) {
  padding-bottom: $size + px;
}

@for $i from 1 through $spaces {
  .margin-#{$i} {
    @include margin($i);
  }
  .margin-top-#{$i} {
    @include margin-top($i);
  }
  .margin-left-#{$i} {
    @include margin-left($i);
  }
  .margin-right-#{$i} {
    @include margin-right($i);
  }
  .margin-bottom-#{$i} {
    @include margin-bottom($i);
  }
  .padding-#{$i} {
    @include padding($i);
  }
  .padding-top-#{$i} {
    @include padding-top($i);
  }
  .padding-left-#{$i} {
    @include padding-left($i);
  }
  .padding-right-#{$i} {
    @include padding-right($i);
  }
  .padding-bottom-#{$i} {
    @include padding-bottom($i);
  }
  .padding-y-#{$i} {
    @include padding-top($i);
    @include padding-bottom($i);
  }
  .padding-x-#{$i} {
    @include padding-left($i);
    @include padding-right($i);
  }
}

// hadling the width in percentage
@mixin width($width) {
  width: percentage($width/100) !important;
}

@for $i from 1 through 100 {
  .width-#{$i} {
    @include width($i);
  }
}

// hadling the width in pixels
@mixin w($size) {
  width: $size + px;
}

@mixin h($size) {
  height: $size + px;
}

@for $i from 1 through 5000 {
  .w-#{$i}-px {
    @include w($i);
  }

  .h-#{$i}-px {
    @include h($i);
  }
}

// hadling fixed width in pixels
@mixin fixed-w($size) {
  width: $size + px !important;
  min-width: $size + px !important;
  max-width: $size + px !important;
}

@mixin fixed-h($size) {
  height: $size + px !important;
  min-height: $size + px !important;
  max-height: $size + px !important;
}
// usage fixed-w-100-px
@for $i from 1 through 5000 {
  .fixed-w-#{$i}-px {
    @include fixed-w($i);
  }

  .fixed-h-#{$i}-px {
    @include fixed-h($i);
  }
}
