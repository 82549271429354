/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
// @import './custom/abodaif.scss';
@import './colors';
@import './variables/variables';
@import './spacer';
@import './scrollbar';


input::placeholder:not(.igs-input-text) {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(92, 92, 92) !important;
  opacity: 0.8 !important;
}

input:-ms-input-placeholder:not(.igs-input-text) {
  /* Internet Explorer 10-11 */
  color: rgb(92, 92, 92) !important;
  opacity: 0.8 !important;
}

input::-ms-input-placeholder:not(.igs-input-text) {
  /* Microsoft Edge */
  color: rgb(92, 92, 92) !important;
  opacity: 0.8 !important;
}

input[type='file']::file-selector-button {
  background: #f7f7f7;
  font-weight: 500;
  cursor: pointer;
}
input[type='file']::-ms-browse {
  background: #f7f7f7;
  font-weight: 500;
  cursor: pointer;
}

input[type='file']::-webkit-file-upload-button {
  background: #f7f7f7;
  font-weight: 500;
  cursor: pointer;
}

.table {
  color: #606060;
}

div.table-editable-cell[contenteditable] {
  //outline: 0px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 4px;
  border-radius: 4px;
  &:focus {
    outline: 2px solid #6f83ef;
    font-weight: bold;
  }
}

.very-small {
  font-size: 10px !important;
}

.fake-border {
  border: 1px solid transparent;
  box-sizing: border-box;
}

.add-role-icon {
  color: rgb(180, 180, 180);
  &:hover {
    //color: var(--bs-success);
    color: var(--bs-primary);
  }
}

/* .form-status-switch span{
  color: #ffffff !important;
}
.form-status-switch .form-check-input:not(:checked) {
  border-color: #f63737;
  background-color: #f63737;
}

.form-status-switch .form-check-input:checked {
  border-color: #28c76f;
  background-color: #28c76f;
} */
.swal2-popup {
  padding: 0 0 2.25em !important;
}

.swal2-title {
  font-size: 1.3em;
}

.swal2-html-container {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  display: flex !important;
  justify-content: center !important;
}

.rmdp-ym .rmdp-day span {
  border-radius: 6px;
  font-size: 13px;
}

.css-k3ockt-control {
  border-color: #d8d6de;
}

.clear-input-span {
  right: 5px;
  top: -2px;
}

.igs-border {
  border-color: #b2b2b3 !important;
}

.dot-badge {
  width: 8px;
  height: 8px;
  background:rgb(255, 8, 0);
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  line-height:8px;
  vertical-align:middle;
  text-align:center;
  color: white;
  position: absolute;
  right: -2px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.form-label {
  font-size: 0.9rem;
  color: #444444;
  font-weight: 500;
}
/* 
label {
  color: #444444;
  font-weight: 500;
} */

.text-left {
  text-align: left !important;
}
.text-justify {
  text-align: justify !important;
}

.text-right {
  text-align: right !important;
}

.text-yellow {
  color: rgb(255, 255, 0) !important;
}

.text-green-light {
  color: #aabf7d !important; //rgba(255, 217, 0, 0.486)
}
.menu-header-text-color {
  color: #9fc928 !important;
}

.mx-0-5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mb-0-5 {
  margin-bottom: 0.5rem;
}

.currencies-container {
  min-height: 450px;
  max-height: 500px;
  overflow-y: scroll;
}

.currency-container {
  padding: 5px;
  cursor: default;
}

.currency-container:hover {
  background-color: #eeeeff;
}

span.currency-plus {
  display: none;
}

.currency-container:hover span.currency-plus {
  display: block;
}

.unselectable > * {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.igs-navbar-header {
  //background: #fff;
  height: auto !important;
  margin-bottom: 5px !important;
  padding: 1rem 0.3rem 1rem 0.3rem !important;

  li.nav-item:first-child {
    display: flex;
  }
  .navbar-brand {
    display: flex;
    align-items: center;
  }
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  z-index: 0 !important;
}

div.is-invalid-phone {
  input.form-control,
  div.flag-dropdown {
    border: 1px solid #f00;
  }
}

.formBtnWidth {
  //width: 100px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width:576px) {
  .formBtnWidth {
    width: 50%;
  }
  
}

.soft-squared-logo {
  border-radius: 2px !important;
  background: transparent !important;
  overflow: hidden !important;
  * {
    border-radius: 0 !important;
  }
}

ul.custom-main-nav {
  padding-bottom: 80px !important;
  li.nav-item.has-sub {
    white-space: break-spaces !important;
    margin-top: 2px !important;
    margin-top: 2px !important;
    a {
      border-radius: 4px !important;
    }
  }
}

ul.custom-main-nav li > ul li.nav-item {
  font-size: 0.96rem;
  & span {
    white-space: normal !important;
  }
}

ul.custom-main-nav li.nav-item span {
  font-size: 0.99rem;
  font-weight: 500;
}

.main-menu.menu-dark .navigation .navigation-header {
  //margin: calc(2rem + 0.2rem) 0 0.8rem 20px;
}
.custom-main-nav > li > a {
  margin-left: 10px !important;
}

.custom-main-nav > li > a svg {
  margin-right: 8px !important;
}

/*
ul.custom-main-nav li > ul li.nav-item.active {
  //font-size: 1rem;
}
*/

ul.custom-main-nav li > ul svg {
  margin-right: 8px;
  color: $site-color !important;
}

.nav-item.active {
  background: unset !important;
  box-shadow: unset !important;
  border-radius: 0px !important;
  & span {
    color: $active-menu-color !important;
    font-weight: 500;
    white-space: normal !important;
  }
}

.main-menu.menu-dark .navigation > li.active > a {
  background: unset !important;
  box-shadow: unset !important;
  border-radius: 0px !important;
}

.border-radius-6px {
  border-radius: 6px;
}
.igs-active-nav {
  a {
    background: transparent !important;
    box-shadow: none !important;
    .menu-item {
      color: #28c76f;
      font-weight: bold;
    }
  }
}

.menu-collapsed .main-menu span.menu-item,
.menu-collapsed .main-menu span.menu-title {
  display: none;
}

.menu-collapsed .main-menu li.nav-item > a {
  justify-content: center;
  // margin: 0 auto;
  text-align: center;
}

.menu-collapsed .main-menu.expanded span.menu-item,
.menu-collapsed .main-menu.expanded span.menu-title {
  display: flex;
}

.menu-collapsed .main-menu.expanded li.nav-item > a {
  justify-content: unset;
  /*margin: unset;*/
  text-align: unset;
}

.igs-panel-title {
  color: #9fc928 !important;
  font-weight: bold;
  font-size: 28px;
  padding-left: 30px;
}

.dropdown-user-link {
  .user-role {
    color: #9fc928;
  }
}
.breadcrumb-item.active {
  color: #6dab70 !important;
}
.igs-nav-pill {
  .nav-link.active {
    background-color: #9fc928 !important;
    color: #3b4253 !important;
  }
  .nav-link {
    margin-right: 1rem;
    background-color: #3b4253 !important;
    color: #abafb5;
    border: none;
    font-weight: bold;
    box-shadow: none !important;
  }
}
.text-underline {
  text-decoration: underline;
}
.igc-text-color-1 {
  color: #b4b7bd;
}
.igs-text-color-2 {
  color: #7b972d;
}
.igc-text-color-3 {
  color: #d0d2d6;
}

.igs-table {
  td[role='cell'] {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  th[role='columnheader'] {
    padding: 10px !important;
    text-align: left !important;
  }

  tr.expanded-row {
    background: #2d2e72d1;
    --bs-table-accent-bg: transparent !important;
    .text-expanded {
      color: #fff !important;
    }
  }

  tr.nohover {
    background: transparent !important;
    --bs-table-accent-bg: transparent !important;
  }
  tr.nohover:hover,
  tr.expanded-row:hover {
    background: #2d2e72d1;
    --bs-table-accent-bg: transparent !important;
  }
}

.igs-table.table-striped {
  tr.expanded-row {
    background: #2d2e72d1;
    --bs-table-accent-bg: transparent !important;
    .text-expanded {
      color: #fff !important;
    }
  }
}

#igs-table-container div.table-responsive {
  min-height: 500px !important;
  td {
    padding: 0.4rem !important;
  }
}

.table-hover:not(.table-rows-expanaded) > tbody > tr:hover {
  --bs-table-accent-bg: #efefef9e !important;
}

.table-hover.table-rows-expanaded > tbody > tr:hover {
  background: #2d2e72d1 !important;
  --bs-table-accent-bg: transparent !important;
  .text-expanded {
    color: #fff !important;
  }
}

.background-transparent {
  background-color: transparent !important;
}

.no-box-shadow {
  box-shadow: unset !important;
}

.igs-bg-auth {
  background-color: #283046 !important;
}
.badge.badge-light-yellow {
  color: #d5f50a;
  background-color: rgba($color: #d5f50a, $alpha: 0.12);
}
.badge.badge-task-light-yellow {
  color: white;
  background-color: #ea5455;
}
.bg-error {
  background-color: #ea5455;
}

.custom-p-mb {
  margin-bottom: 0.2rem;
}
.custom-small {
  font-size: 0.5rem;
}

.text-aquamarine {
  color: #7fffd4 !important;
}

.react-tel-input input.form-control {
  width: 100% !important;
}

.igs-auth-right-section {
  /*width: calc(100% - 386px);*/
  width: 100%;
}
.igs-input-text {
  background-color: transparent !important;
  border-color: #3b4253;

  resize: none;

  label::before {
    background-color: transparent;
    border-color: #3b4253;
  }
  label {
    color: #fff;
  }
  input,
  .input-group-append .input-group-text {
    background-color: transparent !important;
    border-color: #3b4253;
    color: #ffffff;
  }
}
input.igs-input-text {
  color: #ffffff;
}

input.igs-input-text:focus {
  color: #ffffff;
}

.igs-input-text input:focus {
  color: #ffffff;
}

.igs-security-input {
  width: 3rem;
  text-align: center;
}
.required {
  color: $required-color;
  text-transform: capitalize;
}

.modal-parent {
  .modal-content {
    background-color: transparent;
  }
  .modal-header {
    background-color: $modalHeader-color;
    border-radius: 20px 20px 0 0;
    .modal-title {
      color: #fff !important;
    }
  }
  .modal-body {
    background-color: $darkbg-color;
    color: #fff !important;
  }
  .modal-footer {
    background-color: $darkbg-color;
    border-radius: 0 0 20px 20px;
  }
  .form-label {
    color: #fff !important;
  }
}

.modal-btn-action {
  padding: 8px !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.vendor-view-table th {
  min-width: 150px;
  width: auto;
}

#custom-modal {
  .modal-title {
    display: flex;
    width: 100% !important;
    justify-content: space-between;
  }
}

.spinner-parent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secret-link {
  color: $light-dark;
  text-decoration: underline;
  &:hover {
    color: $semi-dark;
    box-shadow: none;
  }
}
.s-admin-header {
  position: absolute;
  top: 17%;
  left: 7%;
  color: #fffb0d;
  font-size: 1.25rem;
  @media screen and (max-width: 991.98px) {
    left: 22%;
  }
}
.react-select-container {
  border: 1px solid $required-color;
  border-radius: 4px;
}
.react-select__value-container {
  color: red;
}
.system-header {
  color: $info-color;
  font-weight: bold;
}
.rg-color {
  color: #e2e9ea;
}
.close-btn-toast {
  background-color: $light-dark;
  color: #fff;
  border: none;
  border-radius: 20%;
  font-weight: bold;
}
.attach-name {
  color: $attach-color;
}
.fit-content {
  width: fit-content !important;
}
.container {
  height: 40px;
  margin: auto;
  width: 100%;
  justify-content: center;
  flex-flow: wrap;
  gap: 3px;
}

.character {
  line-height: 40px;
  min-width: 40px;
  width: 40px;
  font-size: 32px;
  color: $semi-dark;
  background-color: #fff;
  border: 1px solid $info-color;
  border-radius: 8px;
  margin-left: 8px;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.character--selected::after {
  content: '';
  width: 2px;
  height: 25px;
  background: #2e2e2e;
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
}

.character:first-child {
  margin-left: 0;
  cursor: text;
}

.character--inactive {
  background-color: #fff;
}

.character--selected {
  border: 1px solid green;
}
.approval {
  /*border:5px solid $info-color;*/
  border-radius: 20px;
}
.paragraph {
  font-size: 20px;
}
.approval-title {
  font-size: 2.5rem;
}
.approval-text {
  font-size: 1.25rem;
}

div.igs-auth-left-section {
  width: 100%;
  min-width: unset;
}

.sidebar {
  height: 100%;
  width: 380px;
  max-width: 400px;
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  left: 0;
  overflow-x: hidden;
  background-color: transparent !important;
  // background-image: url(../images/pages/login/team-pic.jpg);
  // padding-top: 10px;
  z-index: 999;
  transition: all 1s;
}

.sidebar-shadow {
  box-shadow: 4px 0px 20px 1px black;
  -webkit-box-shadow: 4px 0px 20px 1px black;
  -moz-box-shadow: 4px 0px 20px 1px black;
}

/* The sidebar links */
.sidebar a.closebtn {
  text-decoration: none;
  color: #fff;
  display: none !important;
}

/* When you mouse over the navigation links, change their color */
.sidebar a.closebtn:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
}

/* The button used to open the sidebar */
.openbtn {
  border: none;
  position: absolute;
  z-index: 2;
  border-radius: 0px 5px 5px 0;
  color: #fff;
  background-color: #34343499;
  left: $sidebar-width;
  top: 10px;
  width: 45px;
  height: 45px;
  line-height: 1;
  transition: all 1s;
}

.openbtn:hover {
  background-color: #444;
}

@media screen and (min-width: 1320px) {
  .sidebar {
    width: 380px;
    position: sticky;
    display: block;
  }
  .sidebar a.closebtn {
    display: none;
  }
  .igs-auth-right-section {
    width: calc(100% - 386px);
  }
}

@media only screen and (max-width: 492px) {
  .sidebar a.closebtn {
    font-size: 18px;
  }

  div.igs-auth-left-section {
    min-width: 100%;
    width: 100%;
  }

  .sidebar {
    padding-top: 10px;
    height: 100%;
    width: 100%;
    // max-width: 100%;
  }

  .openbtn {
    left: 280px;
    width: 38px;
    height: 38px;
  }

  div.sidebar-footer,
  div.sidebar-upper {
    font-size: 12px;
  }
  h6.sign-note {
    font-size: 13px;
  }
}

.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

button.step-trigger {
  padding-right: 5px !important;
}

span.bs-stepper-title {
  font-size: 0.9rem;
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-subtitle {
  font-size: 0.8rem;
}

.registration-header {
  height: 65px;
}

/*

::-webkit-scrollbar {
  width: 16px;
  height: 16px; 
}


::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

::-webkit-scrollbar-thumb {
  background: #888; 
}


::-webkit-scrollbar-thumb:hover {
  background: #555; 
}*/

.sidebar-upper {
  justify-content: normal;
  margin-top: 60px;
}

.sidebar-footer {
  margin-top: 1rem;
}
.sidebar {
  flex: 1;
}

.igs-auth-right-section {
  flex: 2;
}

.site-color {
  color: $site-color !important;
}

.bs-stepper .bs-stepper-header .line {
  padding: 1rem !important;
}

.drag-container {
  width: 150px;
  float: left;
  justify-content: center;
  display: flex;
}
.buttons-container {
  width: auto;
  display: flex;
  align-items: center;
}

//uppy svg css
.uppy-DragDrop-label {
  display: none !important;
}
.uppy-DragDrop-inne {
  padding: 0;
}
.uppy-DragDrop-arrow {
  width: 50px;
  height: 61px;
  fill: #e0e0e0;
  margin: 0;
  margin-bottom: 0;
  margin-top: 10px;
}

.uppy-DragDrop-note {
  color: #adadad;
  font-size: 1em;
  display: flex;
  width: 100%;
  flex-flow: row;
}

.toast-close {
  position: absolute;
  top: 5px;
  left: 5px;
}
.custom-toast-header {
  width: 90%;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
}

.fixed-btn-width {
  min-width: 140px;
  width: 140px;
  max-width: 140px;
}

.wizard-right-buttons {
  display: flex;
  justify-content: flex-end;
}

.left {
  float: left;
}
.right {
  float: right;
}

.rc-dyn-tabs-close {
  font-size: 1.5rem;
}

.rc-dyn-tabs-close:hover {
  color: red;
  font-size: 1.8rem;
}

.rc-dyn-tabs-tablist.rc-dyn-tabs-ltr > .rc-dyn-tabs-tab > .rc-dyn-tabs-close {
  top: 12px;
}
@media (max-width:400px) {
  .rc-dyn-tabs-ltr{
    .rc-dyn-tabs-panellist{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab.rc-dyn-tabs-selected > .rc-dyn-tabs-title,
.rc-dyn-tabs-selected {
  //color: var(--bs-light) !important;
}
/*
.rc-dyn-tabs-tab :not(.rc-dyn-tabs-tab.dynamic-tabs-add-tab) {
  max-width: 120px !important;
  width: 120px !important;  
}
*/
.rc-dyn-tabs-title {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  /* width: 210px !important;*/
  max-width: 210px !important;
}
@media (max-width:465px) {
  .rc-dyn-tabs-title {
    font-size: 0.9rem;
}
}
.dynamic-tabs-add-tab {
  /*
  border: 1px solid #6e81ea;
  border-bottom: 2px solid #6e81ea;
  border-bottom: 3px solid #6e81ea;
  margin-left: 5px;
  */
  padding: 11px !important;
  color: #3f51b5 !important;
}

.dynamic-tabs-add-tab:hover {
  background-color: #6e81ea !important;
  color: #fff !important;
  border-bottom: 2px solid #6e81ea;
}

div.dz-loader-container {
  min-width: 100%;
  min-height: 150px;
  border: 2px dashed #c7c7c7;
  border-radius: 5px;
  background: #f9f9f9;
  
}

div.product-image .dropzone {
  min-height: 100px;
  height: 125px;
  width: 125px;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  padding-right: 0;
  padding-left: 0;
}

div.product-image div.dz-preview.dz-image-preview img.image-preview {
  width: 80px;
  height: 80px;
}

.state-item,
.flex-column.state-card-items-container {
  width: max-content;
}

.hover-rotate:hover {
  transition-duration: 0.5s;
  transition-property: transform;
  transform: rotate(360deg);
  transform: scale(1.1);
  -webkit-transform: rotate(360deg);
  color: #4b62e8 !important;
}

@media (min-width: 768px) {
  .state-item,
  .flex-column.state-card-items-container {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .drag-container {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 1rem;
  }
  .buttons-container {
    margin: 0 auto;
  }
  .formBtnWidth {
    width: 100% !important;
    // padding: 6px !important;
  }
}

@media screen and (max-width: 592px) {
  // .wizard-right-buttons {
  //   justify-content: center;
  // }
  .wizard-right-buttons .fixed-btn-width {
    min-width: 50%;
    width: 50%;
    max-width: 50%;
    margin: 5px !important;
  }
}

.rdt_TableCol_Sortable div.lnndaO {
  /* white-space: unset !important; */
  /* font-size: 11px; */
  //width: 100%;
  //min-width: 100%;
  display: flex;
  text-transform: uppercase;
  color: darkgray;
}

.statistics-options div.filter-opt:first-child {
  padding-left: 0px;
}

.statistics-options div.filter-opt:last-child {
  padding-right: 0px;
}

/*
div.rdt_TableCell {
  word-break: normal !important;
  text-align: center !important;
  min-width: unset !important;
}
*/
//repaire perfect scroll bar

.ps .ps__rail-y:hover,
.ps .ps__rail-x:hover,
.ps .ps--clicking {
  background: transparent !important;
  opacity: 0.6 !important;
  cursor: pointer;
}

.ps__thumb-y,
.ps__thumb-x {
  /*height: 6px !important;*/
  background-color: #aaa !important;
}

.form-control:focus:valid,
.form-control:focus.is-valid {
  box-shadow: unset !important;
}

input.form-control:focus {
  background-color: #fff;
  border-color: #7367f0;
}

input.form-control:focus + .flag-dropdown {
  border: 1px solid #7367f0;
  border-right: none;
}

.scale-down {
  object-fit: scale-down !important;
}

.custom-flex-row {
  display: flex;
  flex-wrap: wrap;
}

.flex-2-col {
  flex-grow: 1;
  width: 50%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.flex-3-col {
  flex-grow: 1;
  width: 33%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.flex-4-col {
  flex-grow: 1;
  width: 25%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.no-grow {
  flex-grow: 0 !important;
}

.flex-5-col {
  flex-grow: 1;
  width: 20%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.cdp-check {
  width: '18px';
  height: '18px';
  min-width: '18px';
  min-height: '18px';
}

.custom-input-number input,
.custom-input-number .rc-input-number {
  width: 80%;
  height: 40px;
  min-height: 40px;
}

.custom-input-number .rc-input-number .rc-input-number-handler-wrap {
  top: 0.6rem;
}

.input-number-st {
  width: 60px;
  border-radius: 4px;
  padding: 5px;
  padding-left: 10px !important;
}

.react-numeric-input {
  b {
    cursor: pointer !important;
  }
  i {
    font-size: 15px;
  }
  input {
    width: 90px;
    padding-right: 10px !important;
    text-align: center;
    padding: 5px 10px !important;
    min-width: 80px;
    max-width: 100px;
  }
}

.input-number-container {
  touch-action: none !important;
}

.cdp-form-check {
  flex-basis: 70%;
  flex-grow: 1;
}
/*
.nav-item .nav-link {
  border: solid transparent;
  border-width: 0 0 2px;
  border-radius: 0;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
  font-size: 0.75rem;
  color: rgba(0,0,0,.6);
  padding: 17px 29px 16px;
}
*/

.nav-item .nav-item.show .nav-link,
.nav-item .nav-link.active {
  //color: #1266f1;
  //border-color: #1266f1;
}

.tab-content {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
}

.swiper-slide {
  max-width: 100%;
  overflow: hidden;
  margin-right: 30px;
  width: 100%;
  flex-shrink: 0;
}

.custom-panels-stepper {
  .bs-stepper-header {
    display: none !important;
  }
}

.table-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.table-dropdown:last-child {
  margin-right: 0px;
}

.table-dropdown button {
  padding-left: 8px;
  padding-right: 8px;
}

.table-dropdown .dropdown-toggle::after {
  left: 4px !important;
}

.datatable-img {
  margin-top: 8px;
  margin-bottom: 8px;
}

.datatable-img > .avatar-content {
  width: 60px;
  height: 60px;
}

.main-menu.menu-dark .navigation > li ul li ul a {
  padding: 10px 15px 10px 35px;
  span {
    font-size: 0.92rem;
  }
}

ul.side-nav-pills li.nav-item {
  flex-grow: 1 !important;
  width: 100% !important;
}

.side-nav-pills .nav-link {
  text-align: left;
  justify-content: start;
  flex-grow: 1;
}

.visitors-phone-num {
  font-size: 14px;
  padding-left: 5px;
  font-weight: 500;
  line-height: 1.7;
}

.react-datepicker-popper {
  z-index: 9999999 !important;
}

.employees-calendar {
  .fc-toolbar-chunk {
    div:first-child {
      display: flex;
      align-items: center;
    }
    button.fc-prev-button,
    button.fc-next-button {
      font-size: 1.4rem;
    }
  }
}
.app-calendar .fc-toolbar h2 {
  font-size: 1.6rem;
}

.fc-header-toolbar .fc-toolbar {
  align-items: center;
}

.rounded-permission-checkbox {
  border-radius: 50% !important;
}

@media (max-width: 992px) {
  .fc-toolbar-chunk {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
  }
  .employees-calendar {
    .fc-toolbar-chunk {
      button.fc-prev-button,
      button.fc-next-button {
        font-size: 1rem;
      }
    }
  }
  .app-calendar .fc-toolbar h2 {
    font-size: 1.1rem;
  }
}

.select__control .select__indicator svg,
.react-select__control .select__indicator svg {
  color: #c3c3c3 !important;
}

/*
div.select__control  {
  z-index: 0 !important;
}
div.select__menu,  div.select__menu > *{
  z-index: 2 !important;
}*/

/*
#clear-search-table {
  border: 1px solid #d8d6de;
  border-left: 0;
}

#clear-search-table:visited, #clear-search-table:hover, #clear-search-table:focus, #clear-search-table:active {
  border: 1px solid #7367f0;
  border-left: 0;
  color: #ea5455;
}
*/

/*
button.table-filter-btn {
}
*/
/*
th[role="columnheader"] div.d-flex {
  justify-content: center;
  text-align: center;
}
*/
td[role="cell"] {
  text-align: center;  
  align-items: center;
  justify-content: center;
}

.company-info-navtabs {
  display: flex;
  justify-content: center;
  align-items: baseline;
  li.nav-item {
    align-self: stretch;
    display: flex;
    flex-basis: 33.33%;
    //min-width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    .nav-link {
      width: 100%;
      text-align: center !important;
      border-radius: 0px;
      &.active {
        //border-radius: 0px !important;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1360px) {
  .company-info-navtabs {
    li.nav-item {
      flex-basis: 50%;
    }
  }
}

.dz-details img {
  width: 110px;
  height: 120px;
}

/*
.pagination-sm .page-item:first-child .page-item:last-child .page-link {
  border-radius: 50% !important;
}
*/

/*.btn-aquamarine {
  background-color: $aquamarine !important;
}
*/

.page-item.active {
  height: -moz-fit-content;
  height: fit-content;
  height: -webkit-fill-available;
}

// Display a cross with CSS only.
//
// $size  : px or em
// $color : color
// $thickness : px
@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }
}

// Example 1.
.repeater-btn-delete {
  margin: 0;
  border: 0;
  padding: 0;
  background: rgb(255, 85, 43);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  right: 0;
  top: 10px;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 150ms;

  .icon-cross {
    @include cross(14px, #fff, 2px);
  }

  &:hover,
  &:focus {
    transform: rotateZ(90deg);
    background: rgb(204, 0, 0);
  }
}

// For screen readers.
.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

@media (min-width: 992px) {
  div.modal-dialog#medium-modal {
    max-width: 84%;
  }
  div.modal-dialog.modal-xl#medium-modal {
    max-width: 94%;
  }
}
//bootsrap lg breack point
@media only screen and (max-width: 992px) {
  [class*='-col'] {
    width: 100%;
    //padding-right: 0 !important;
    //padding-left: 0 !important;
  }
  .modal-xl {
    width: 95%;
    max-width: 95%;
    padding-right: 0;
    padding-left: 0;
  }
}

@media only screen and (max-width: 578px) {
  .character {
    line-height: 35px;
    min-width: 35px;
    width: 35px;
    font-size: 25px;
    color: #545b6d;
  }
  .container {
    height: 35px;
    flex-flow: row;
  }
  .character--selected::after {
    height: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .character {
    line-height: 30px;
    min-width: 30px;
    width: 30px;
    font-size: 20px;
    color: #545b6d;
  }
  .container {
    height: 30px;
    flex-flow: row;
    grid-gap: 5px;
    gap: 5px;
  }
  .character--selected::after {
    height: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .statistics-options div.filter-opt {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 1rem;
  }

  input#search-invoice {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1770px) {
  .expenses-tasks-container {
    width: 100%;
  }
}

// .main-menu.menu-dark .navigation > li > a {
//   margin: 0px !important;
// }

.lable-margin {
  margin-top: 3px !important;
}

.flat {
  *,
  .rounded {
    border-radius: 0 !important;
  }
  .repeater-btn-delete {
    border-radius: 50% !important;
  }
}


#react-doc-viewer {
  width: 100% !important;
  height: 100% !important;
  min-height: 500px;
  flex-grow: 1;
  #proxy-renderer {
    width: 100% !important;
    height: 100% !important;
  }
}

.DisabledAction{
  color: #b5b5b5;
  cursor: default !important;
  &:hover {
      background-color: white;
      color: #b5b5b5;
  }
}

.react-datepicker__children-container{
  width: 96.5% !important;
}

.MaintenanceChecks{
  width: 100%;
  Label{
      Input{
          @media (max-width:720px) {
              width: 1rem;
              height: 1rem;
          }
          @media (max-width:660px) {
              width: 0.9rem;
              height: 0.9rem;
          }
          @media (max-width:450px) {
              width: 0.8rem;
              height: 0.8rem;
          }
      }
      span{
          font-size: 1rem;
          cursor: pointer;
          color: gray;
          margin-left: 5px;
          @media (max-width:720px) {
              font-size: 0.9rem;
          }
          @media (max-width:660px) {
              font-size: 0.8rem;
          }
      }
  }
}
.btn-group{
  button{
    @media (max-width:580px) {
      font-size: 1rem;
      padding: 0.55rem 1.1rem;
    }
    @media (max-width:513px) {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
    }
    @media (max-width:470px) {
      font-size: 0.85rem;
      padding: 0.45rem 0.9rem;
    }
    @media (max-width:440px) {
      font-size: 0.75rem;
      padding: 0.4rem 0.8rem;
    }
    @media (max-width:396px) {
      font-size: 0.65rem;
    }
    @media (max-width:370px) {
      font-size: 0.6rem;
      padding: 0.35rem 0.7rem;
    }
    @media (max-width:340px) {
      font-size: 0.51rem;
      padding: 0.35rem 0.7rem;
    }
    
  }
}